import styled from 'styled-components';
import { transparentize } from 'polished';

import theme from 'styles/theme';
import { Grid } from 'components/Grid';
import { H1, Text } from 'components/Text';

export const Wrapper = styled.section`
  padding: 80px 0;
  display: flex;
  flex-direction: column;

  @media (max-width: ${theme.size.tablet}) {
    padding: 40px 0;
  }
`;

export const Inner = styled(Grid)`
  width: 100%;
`;

export const Hero = styled.div`
  padding-top: 70px;

  @media (max-width: ${theme.size.tablet}) {
    padding-top: 40px;
  }
`;

export const Title = styled(H1)`
  margin-bottom: 25px;

  @media (max-width: ${theme.size.tablet}) {
    margin-bottom: 15px;
  }
`;

export const Description = styled(Text)`
  max-width: 800px;
  line-height: 1.8;
`;

export const Placeholder = styled.div`
  text-align: center;
  background-color: white;
  border: 1px solid ${theme.color.paper2};
  padding: 70px 20px;

  @media (max-width: ${theme.size.tablet}) {
    padding: 50px 20px;
  }
`;

export const ResourcesWrapper = styled.section`
  padding: 70px 0 50px;
  background-color: ${transparentize(0.5, theme.color.paper2)};

  @media (max-width: ${theme.size.tablet}) {
    padding: 50px 0;
  }
`;

export const Resources = styled(Grid)`
  width: 100%;
  display: grid;
  margin-top: 30px;
  align-items: flex-start;
  grid-gap: 12px;
  grid-template-columns: repeat(auto-fit, minmax(240px, 1fr));

  @media (max-width: ${theme.size.tablet}) {
    margin-top: 25px;
  }
`;
