import React from 'react';

import nextIcon from 'img/arrow-right-white.svg';

import { Wrapper, Icon } from './styles';

const ArrowButton = ({ onClick, type }) => {
  return (
    <Wrapper type={type} onClick={onClick}>
      <Icon type={type} src={nextIcon} />
    </Wrapper>
  );
};

export default ArrowButton;
