import React from 'react';

import { Wrapper, Inner, Breadcrumb } from './styles';

const Breadcrumbs = ({ children }) => {
  return (
    <Wrapper>
      <Inner>{children}</Inner>
    </Wrapper>
  );
};

export default Breadcrumbs;
export { Breadcrumb };
