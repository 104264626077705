import styled from 'styled-components';
import { transparentize } from 'polished';

import Button from 'components/Button';

export const Wrapper = styled(Button)`
  border-radius: 50%;
  width: 45px;
  height: 45px;
  justify-content: center;
  align-items: center;
  position: absolute;
  bottom: 16px;
  left: 25px;
  padding: 0;
  border: none;
  z-index: 10;
  transform: translateX(-100px);
  background-color: ${transparentize(0.8, 'black')};

  &:hover {
    background-color: ${transparentize(0.7, 'black')};
  }
`;

export const Icon = styled.img`
  width: 15px;
  height: 15px;
  display: block;
  margin: auto;
  transform: translateX(1px);
`;
