import styled from 'styled-components';
import { Link } from 'gatsby';
import styledMap from 'styled-map';
import { height, space } from 'styled-system';
import { transparentize } from 'polished';

import theme from 'styles/theme';
import { Text, H3 } from 'components/Text';
import { Image as StyledImage } from 'components/Media';

import { Wrapper as Button } from './ArrowButton/styles';

export const Image = styled(StyledImage)`
  height: 100%;
  width: 100%;
  max-height: 220px;
  position: relative;
  object-fit: cover;
  transition: ${theme.transition};
  transform-origin: bottom;

  ${height};
`;

export const Descripton = styled(Text)`
  font-size: ${theme.font.size.zeta};
  line-height: 1.55;
  min-height: 35px;
  color: ${transparentize(0.3, 'white')};
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
  transition: ${theme.transition};
  -moz-osx-font-smoothing: auto;
  -webkit-font-smoothing: subpixel-antialiased;
`;

export const Wrapper = styled(Link)`
  display: flex;
  flex-direction: column;
  margin-bottom: 12px;
  position: relative;
  overflow: hidden;
  transition: ${theme.transition};

  ${space};

  background-color: ${styledMap('color', {
    purple: theme.color.purple,
    blue: theme.color.blue,
    green: theme.color.green,
    red: theme.color.red,
    pink: theme.color.pink,
    yellow: theme.color.yellow,
    default: theme.color.purple
  })};

  &:hover {
    transform: scale(1.02);
    box-shadow: 0 2px 25px 0 ${transparentize(0.8, 'black')};

    ${Image} {
      opacity: 1;
      transform: scale(1.08);
    }

    ${Descripton} {
      color: white;
      opacity: 0;
    }

    ${Button} {
      transform: translateX(0);
    }
  }
`;

export const Inner = styled.div`
  padding: 22px 28px 25px;
  position: relative;
`;

export const Heading = styled(H3)`
  margin: 0 0 12px;
  color: white;
  line-height: 1.1;
`;
