import styled from 'styled-components';
import { Link } from 'gatsby';
import { transparentize } from 'polished';
import styledMap from 'styled-map';

import theme from 'styles/theme';
import { Text, H3 } from 'components/Text';
import { Image as StyledImage } from 'components/Media';

export const Title = styled(H3)`
  line-height: 1.15;
  color: ${theme.color.teal};
  transition: ${theme.transition};

  color: ${styledMap('category', {
    Experience: 'white',
    default: theme.color.teal
  })};
`;

export const Image = styled(StyledImage)`
  width: 100%;
  height: auto;
  max-height: 200px;
  object-fit: cover;
  display: block;
  transform-origin: bottom;
  transition: ${theme.transition};
`;

export const Wrapper = styled(Link)`
  display: block;
  margin-bottom: 12px;
  overflow: hidden;
  border: 1px solid ${transparentize(0.8, theme.color.teal)};

  background-color: ${styledMap('category', {
    Experience: theme.color.teal,
    default: 'white'
  })};

  &:hover {
    transform: scale(1.02);
    border: 1px solid ${transparentize(0.6, theme.color.teal)};
    box-shadow: 0 4px 10px ${transparentize(0.9, theme.color.teal)};

    background-color: ${styledMap('category', {
      Experience: theme.color.teal,
      default: transparentize(0.98, theme.color.teal)
    })};

    ${Image} {
      transform: scale(1.08);
    }

    ${Title} {
      opacity: 1;
    }
  }
`;

export const Inner = styled.div`
  padding: 35px;

  @media (max-width: ${theme.size.desktop}) {
    padding: 35px 30px;
  }
`;

export const Description = styled(Text)`
  color: ${theme.color.gray2};
  line-height: 1.7;
  font-size: ${theme.font.size.epsilon};
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  overflow: hidden;

  color: ${styledMap('category', {
    Experience: transparentize(0.1, 'white'),
    default: theme.color.gray2
  })};
`;

export const Icon = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: flex-start;
  margin-bottom: 22px;

  img {
    display: block;
    width: 50px;
    height: 50px;
    padding: 10px;
    border-radius: ${theme.radius};

    background-color: ${styledMap('category', {
      Experience: transparentize(0.8, 'white'),
      default: transparentize(0.9, theme.color.teal)
    })};
  }
`;
