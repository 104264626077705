import React, { useContext } from 'react';
import { navigate } from 'gatsby';
import { globalHistory } from '@reach/router';

import { UserContext } from 'utils/firebase';
import theme from 'styles/theme';
import Loader from 'components/Loader';

import { Wrapper } from './styles';

export default function Protected(props) {
  const firebase = useContext(UserContext);
  const { pathname } = globalHistory.location;

  if (!firebase.readyToFetch) {
    return (
      <Wrapper>
        <Loader size="80px" color={theme.color.teal} />
      </Wrapper>
    );
  }

  if (
    firebase.readyToFetch &&
    !firebase.refUser &&
    !firebase.user &&
    pathname !== '/'
  ) {
    navigate('/');
    return null;
  }

  return props.children;
}
