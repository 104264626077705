import React from 'react';

import { Wrapper, Inner, Title, Description, Image, Icon } from './styles';

const ResourceCard = ({ slug, content, resource, parentTitle, parentSlug }) => {
  const resourceIcon =
    content.category === 'Experience'
      ? `/media/${content.icon}-white.svg`
      : `/media/${content.icon}-teal.svg`;

  return (
    <Wrapper
      to={slug}
      state={{ parentTitle: parentTitle, parentSlug: parentSlug }}
      category={content.category}
    >
      {content.category === 'Experience' && (
        <Image content={content.page.image} />
      )}
      <Inner>
        <Icon category={content.category}>
          <img src={resourceIcon} alt="" />
        </Icon>
        <Title category={content.category}>{content.page.title}</Title>
        <Description category={content.category}>
          {content.page.description}
        </Description>
      </Inner>
    </Wrapper>
  );
};

export default ResourceCard;
