import React from 'react';
import { graphql } from 'gatsby';

import theme from 'styles/theme';
import Layout from 'components/Layout';
import Protected from 'components/Protected';
import { Grid, Masonry } from 'components/Grid';
import { H3, Text } from 'components/Text';
import Button from 'components/Button';
import Breadcrumbs, { Breadcrumb } from 'components/Breadcrumbs';
import ResourcesCard from 'components/ResourcesCard';

import {
  Wrapper,
  Inner,
  Hero,
  Title,
  Description,
  Placeholder,
  ResourcesWrapper,
  Resources
} from './styles';
import ResourceCard from './ResourceCard';

export const LabResourcesPageTemplate = ({
  page,
  resources,
  slug,
  related
}) => {
  const breakpoints = {
    default: 3,
    800: 2,
    600: 1
  };

  const content = page.page;

  const filteredResources = resources.filter(resource => {
    return resource.node.frontmatter.related.includes(page.id);
  });

  const shuffledResources = related.sort(() => 0.5 - Math.random());
  const suggestedResources = shuffledResources.slice(0, 4);

  return (
    <Protected>
      <Breadcrumbs>
        <Breadcrumb to="/">Intelligence Lab</Breadcrumb>
        <Breadcrumb as="span">{content.title}</Breadcrumb>
      </Breadcrumbs>
      <Hero>
        <Grid>
          <Title>{content.title}</Title>
          <Description>{content.description}</Description>
        </Grid>
      </Hero>
      <Wrapper>
        {/* <Inner>
          <H3 mb={["12px", "20px"]}>Resources</H3>
        </Inner> */}
        <Inner>
          {filteredResources.length > 0 ? (
            <Masonry breakpointCols={breakpoints} gutter="12px">
              {filteredResources.map(item => (
                <ResourceCard
                  key={item.node.id}
                  content={item.node.frontmatter}
                  resource={content.title}
                  slug={item.node.fields.slug}
                  parentSlug={slug}
                  parentTitle={content.title}
                />
              ))}
            </Masonry>
          ) : (
            <Placeholder>
              <H3>No resources were found.</H3>
              <Text color={theme.color.gray}>
                Check back soon, more resources are being added frequently.
              </Text>
              <Button mt="50px" to="/">
                Return to Labs
              </Button>
            </Placeholder>
          )}
        </Inner>
      </Wrapper>
      <ResourcesWrapper>
        <Inner>
          <H3>Suggested Team Resources</H3>
        </Inner>
        <Resources>
          {suggestedResources.map(item => {
            const { slug } = item.node.fields;
            const content = item.node.frontmatter;
            return (
              <ResourcesCard
                mb="0"
                to={slug}
                imageHeight="150px"
                key={item.node.id}
                content={content}
                state={{ parent: content.title }}
                parentSlug={slug}
                parentTitle={content.title}
              />
            );
          })}
        </Resources>
      </ResourcesWrapper>
    </Protected>
  );
};

// LabResourcesPageTemplate.propTypes = {
//   title: PropTypes.string
// };

const LabResourcesPage = ({ data }) => {
  const { frontmatter: page } = data.page;
  const { edges: resources } = data.resources;
  const { edges: related } = data.related;

  return (
    <Layout content={page} shade="light">
      <LabResourcesPageTemplate
        page={page}
        slug="/"
        resources={resources}
        related={related}
      />
    </Layout>
  );
};

export default LabResourcesPage;

export const labResourcesPageQuery = graphql`
  query LabResourcesPage($id: String!) {
    page: markdownRemark(id: { eq: $id }) {
      fields {
        slug
      }
      frontmatter {
        id
        page {
          title
          description
        }
      }
    }
    related: allMarkdownRemark(
      filter: { frontmatter: { templateKey: { eq: "resources-page" } } }
    ) {
      edges {
        node {
          id
          fields {
            slug
          }
          frontmatter {
            id
            title
            page {
              title
              description
              image {
                childImageSharp {
                  fluid(maxWidth: 500, quality: 60) {
                    ...GatsbyImageSharpFluid_noBase64
                  }
                }
              }
            }
            color
          }
        }
      }
    }
    resources: allMarkdownRemark(
      filter: { frontmatter: { templateKey: { eq: "resource-page" } } }
    ) {
      edges {
        node {
          id
          fields {
            slug
          }
          frontmatter {
            title
            page {
              title
              description
              image {
                childImageSharp {
                  fluid(
                    maxWidth: 500
                    quality: 60
                    duotone: { highlight: "#ffffff", shadow: "#00B7CD" }
                  ) {
                    ...GatsbyImageSharpFluid_noBase64
                  }
                }
              }
            }
            related
            category
            icon
          }
        }
      }
    }
  }
`;
