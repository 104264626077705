import styled from 'styled-components';
import { Link } from 'gatsby';
import { transparentize } from 'polished';
import { color } from 'styled-system';

import theme from 'styles/theme';
import { Grid } from 'components/Grid';

export const Wrapper = styled.div`
  position: relative;
  z-index: 2;
  backdrop-filter: blur(3px);
  background-color: ${transparentize(0.1, 'white')};
  border-bottom: 1px solid ${theme.color.paper2};
`;

export const Inner = styled(Grid)`
  overflow-x: auto;
  overflow-y: hidden;
  white-space: nowrap;
  padding-top: 10px;
  padding-bottom: 12px;
  -webkit-overflow-scrolling: touch;
`;

export const Breadcrumb = styled(Link)`
  padding-right: 16px;
  margin-right: 6px;
  position: relative;
  font-weight: ${props =>
    props.to ? theme.font.weight.regular : theme.font.weight.regular};
  color: ${props => (props.to ? theme.color.indigo : theme.color.indigo3)};
  opacity: ${props => (props.to ? '1' : '0.5')};

  &:hover {
    color: ${props => (props.to ? theme.color.teal : theme.color.indigo3)};
  }

  &::after {
    content: '›';
    position: absolute;
    right: 0;
    top: calc(50% - 3px);
    opacity: 0.5;
    color: ${theme.color.indigo3};
    font-weight: ${theme.font.weight.regular};
    font-size: ${theme.font.size.cesar};
    transform: translateY(-50%);
  }

  &:last-child::after {
    display: none;
  }

  ${color};
`;
