import React from 'react';

import ArrowButton from './ArrowButton';
import { Wrapper, Inner, Image, Heading, Descripton } from './styles';

const ResourcesCard = ({ content, imageHeight, ...rest }) => {
  return (
    <Wrapper color={content.color} {...rest}>
      <Image height={imageHeight} content={content.page.image} />
      <Inner>
        <ArrowButton />
        <Heading>{content.page.title}</Heading>
        <Descripton>{content.page.description}</Descripton>
      </Inner>
    </Wrapper>
  );
};

export default ResourcesCard;
